import { gql } from '@apollo/client';

export const residencesRowQuery = gql`
  query ResidencesRowQuery {
    residences(limit: 4, sort: RANDOM) {
      id
      name
      price {
        perUnit
        total {
          minimum
          maximum
        }
        currency
      }
      address
      shortAddress
      deadline
      coverUrl
      path
    }
  }
`;

export const residencesQuery = gql`
  query ResidencesQuery {
    residences(sort: RANDOM) {
      id
      name
      city {
        name
      }
      price {
        perUnit
        total {
          minimum
          maximum
        }
        currency
      }
      address
      deadline
      coverUrl
      path
      metroName
      companyName
      updatedAt
    }
  }
`;

export function getResidenceDataQuery(id) {
  const name = `GetResidenceDataQuery_${id}`;
  return gql`
    query ${name}($id: ID!) {
      residence(id: $id) {
        id
        address
        logoUrl
        showBrandHeader
        apartmentRooms
        apartments {
          bathroomsCount
          balconiesCount
          repair {
            name
          }
          area
          currency
          description
          id
          mortgagePriceFrom
          priceFrom
          rooms
          photos {
            full
            f660x496
          }
        }
        briefInfo
        brochureUrl
        city {
          id
          name
        }
        companyName
        contactEmail
        coverUrl
        deadline
        description
        hits
        lat
        lng
        metroName
        name
        phonesMasked
        photos {
          full
          f660x496
        }
        price {
          currency
          perUnit
          total {
            maximum
            minimum
          }
        }
        shortAddress
        updatedAt
        website
        youtubeVideoId
        buildingClass {
          name
        }
        buildingsCount
        blocks
        ceilingHeight
        apartmentsCount
        liftsPerBlock
        floorsPerBuilding
        apartmentsPerFloor
        decoration {
          name
        }
        windowType {
          name
        }
        technology {
          name
        }
        layout {
          name
        }
        hasBillOfSale
        facilities {
          name
          iconUrl
        }
        constructionProgresses {
          name
          date
          youtubeVideoId
          photos {
            full
          }
          isPromo
        }
        infrastructures {
          distance
          infrastructure {
            name
            iconUrl
          }
        }
        specialOffers {
          name
          description
          offerType
        }
        itemsCount
        locations {
          id
          name
          fullName
        }
      }
    }
  `;
}
